<template>
  <div class="container-fluid pb-3">
    <div class="contact-header">
      <div class="row">
        <div class="col">
          <p class="medium text-white">Contact Form</p>
        </div>
        <div class="col">
          <img class="close-button"
               src="../../assets/images/close.png"
               @click="$emit('close')">
        </div>
      </div>
    </div>
    <p class="small text-center mt-2">{{ submissionMessage }} </p>

    <b-form @submit="submitForm">
      <b-form-group>
        <b-form-input id="name"
                      type="text"
                      aria-describedby="formName"
                      autocomplete="off"
                      placeholder="NAME*"
                      :state="form.name !== null ? !$v.form.name.$error : null"
                      @input="$v.form.name.$touch()"
                      v-model="form.name">
        </b-form-input>
        <b-form-invalid-feedback id="formName">
          Name is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-input id="email"
                      type="email"
                      aria-describedby="formEmail"
                      autocomplete="off"
                      placeholder="EMAIL*"
                      :state="form.email !== null ?
                      !$v.form.email.$error : null"
                      @input="$v.form.email.$touch()"
                      v-model="form.email">
        </b-form-input>
        <b-form-invalid-feedback id="formEmail">
          Email is invalid
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-textarea id="message"
                         aria-describedby="formMessage"
                         placeholder="MESSAGE*"
                         :max-rows="6"
                         :rows="3"
                         :state="form.message !== null ?
                          !$v.form.message.$error : null"
                         @input="$v.form.message.$touch()"
                         v-model="form.message">
        </b-form-textarea>
        <b-form-invalid-feedback id="formMessage">
          Message is required
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="text-center">
        <b-button class="btn btn-danger btn-h3-small"
                  type="submit"
                  :disabled="$v.form.$invalid">
          Send Message
        </b-button>
      </div>
    </b-form>

    <!-- Loading -->
    <loading :active.sync="isLoading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  import { required, email } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name  : 'ContactForm',
    props : {
      visibleFooter : String,
      email         : String,
      name          : String,
      uccId         : String,
    },
    data() {
      return {
        form : {
          name    : (this.name) ? this.name : null,
          email   : (this.email) ? this.email : null,
          message : null,
        },
        footer            : this.visibleFooter,
        submissionMessage : '',
        isLoading         : false,
      };
    },
    validations : {
      form : {
        name    : { required },
        email   : { required, email },
        message : { required },
      },
    },
    components : {
      Loading,
      RainbowLoader,
    },
    methods : {

      /**
       * Submit Form
       *
       * @param e
       */
      submitForm : function (e) {
        e.preventDefault();

        this.isLoading = true;
        const endpoint = (this.uccId) ? 'reportedContentEmail' : 'contactEmail';

        const params = {
          'name'    : this.form.name,
          'email'   : this.form.email,
          'message' : this.form.message,
        };

        if (this.uccId) {
          params.uccId = this.uccId;
          params.status = 4;
        }
        
        this.$http.post('/auth/' + endpoint, params)
          .then((res) => {
            this.isLoading = false;

            this.submissionMessage = 'Thank you for contacting us.';

            this.form.name = '';
            this.form.email = '';
            this.form.message = '';

            this.$nextTick(() => { this.$v.$reset(); });
          }).catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }).finally(() => {
            this.$analytics.fbq.event('Lead');
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/contact-form";
</style>
